.ShowControlGroup {
    margin: auto;
    text-align: center;
}

.ShowMoreIcon{
    transition: 0.4s;
}

.ShowLessIcon{
    transition: 0.4s;
    transform: rotateZ(180deg);

}

.ExpandingSubSection {
    transition: height 0.4s;
    overflow: hidden;
}
