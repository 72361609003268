
.SlideCarousel {
    margin-top: 10px;
}

.Slide {
    position: absolute;
    margin: auto;
    /*max-width: 60%;*/
    transition: 2s;
    left: 0;
    right: 0;
    text-align: center;


}

.SlideSelected {
    z-index: 2000;
    pointer-events: none;
}



.SlideFarLeft {
    transform: translateX(-100vw) scale(0.4);
    transition: 2s;
    z-index: 1000;
    /*display: none;*/
    visibility: hidden;

}

.SlideNextLeft{
    transform: translateX(-30%) scale(0.8);
    transition: 2s;
    z-index: 1000;

}

.SlideNextRight{
    transform: translateX(30%) scale(0.8);
    transition: 2s;
    z-index: 1000;

}

.SlideFarRight {
    transform: translateX(100vw) scale(0.4);
    transition: 2s;
    z-index: 1000;
    /*display: none;*/
    visibility: hidden;

}


.SlideFarLeftMobile {
    transform: translateX(-100vw) scale(0.4);
    transition: 2s;
    z-index: 1000;
    /*display: none;*/
    visibility: hidden;

}

.SlideNextLeftMobile{
    transform: translateX(-50%) scale(0.6);
    transition: 2s;
    z-index: 1000;

}

.SlideNextRightMobile{
    transform: translateX(50%) scale(0.6);
    transition: 2s;
    z-index: 1000;

}

.SlideFarRightMobile {
    transform: translateX(100vw) scale(0.4);
    transition: 2s;
    z-index: 1000;
    /*display: none;*/
    visibility: hidden;

}

.SlideNavBar {
    text-align:center;
    padding-top:5px;
}
