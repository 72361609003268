.HeaderBox{
    background-color:black;
    opacity:0.8;

}

.FloatingWrapper{
    background-color: #f4f7f6;
    width:100vw;
    position: relative;
    min-height:100%;
    left:0;
    right:0;
    z-index:1000;


}
.ShowControlGroup {
    margin: auto;
    text-align: center;
}

.ShowMoreIcon{
    transition: 0.4s;
}

.ShowLessIcon{
    transition: 0.4s;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);

}

.ExpandingSubSection {
    transition: height 0.4s;
    overflow: hidden;
}


.SlideCarousel {
    margin-top: 10px;
}

.Slide {
    position: absolute;
    margin: auto;
    /*max-width: 60%;*/
    transition: 2s;
    left: 0;
    right: 0;
    text-align: center;


}

.SlideSelected {
    z-index: 2000;
    pointer-events: none;
}



.SlideFarLeft {
    -webkit-transform: translateX(-100vw) scale(0.4);
            transform: translateX(-100vw) scale(0.4);
    transition: 2s;
    z-index: 1000;
    /*display: none;*/
    visibility: hidden;

}

.SlideNextLeft{
    -webkit-transform: translateX(-30%) scale(0.8);
            transform: translateX(-30%) scale(0.8);
    transition: 2s;
    z-index: 1000;

}

.SlideNextRight{
    -webkit-transform: translateX(30%) scale(0.8);
            transform: translateX(30%) scale(0.8);
    transition: 2s;
    z-index: 1000;

}

.SlideFarRight {
    -webkit-transform: translateX(100vw) scale(0.4);
            transform: translateX(100vw) scale(0.4);
    transition: 2s;
    z-index: 1000;
    /*display: none;*/
    visibility: hidden;

}


.SlideFarLeftMobile {
    -webkit-transform: translateX(-100vw) scale(0.4);
            transform: translateX(-100vw) scale(0.4);
    transition: 2s;
    z-index: 1000;
    /*display: none;*/
    visibility: hidden;

}

.SlideNextLeftMobile{
    -webkit-transform: translateX(-50%) scale(0.6);
            transform: translateX(-50%) scale(0.6);
    transition: 2s;
    z-index: 1000;

}

.SlideNextRightMobile{
    -webkit-transform: translateX(50%) scale(0.6);
            transform: translateX(50%) scale(0.6);
    transition: 2s;
    z-index: 1000;

}

.SlideFarRightMobile {
    -webkit-transform: translateX(100vw) scale(0.4);
            transform: translateX(100vw) scale(0.4);
    transition: 2s;
    z-index: 1000;
    /*display: none;*/
    visibility: hidden;

}

.SlideNavBar {
    text-align:center;
    padding-top:5px;
}

