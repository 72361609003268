.FloatingWrapper{
    background-color: #f4f7f6;
    width:100vw;
    position: relative;
    min-height:100%;
    left:0;
    right:0;
    z-index:1000;


}